import { isAuthor, namespace } from './constants';
import { version } from '../package.json';
/**
 * Map of nested components registered in a AEM Page.
 */
const components = new Map();
/**
 * Sets the component in the available components map.
 * @param name - The name of your component
 * @param component - The Component. The `.vue` component should be passed as parameter.
 * @param version - The version of your component.
 */
export const setComponent = (name, component, version, app) => {
    if (components.has(name)) {
        console.warn(`${namespace}: Component ${name} was already defined.`);
        return;
    }
    components.set(name, {
        name,
        component,
        version,
        app,
    });
};
/**
 * Gets the component from the components map.
 * @param name - Component name.
 * @returns
 */
export const getComponent = (name) => {
    return components.get(name);
};
/**
 * Renders a component inside a specified container.
 * If the component parent element is a slot (AEM), it will use `setComponent` and add the component to `components` map.
 *
 * @param {Object} options - An object containing rendering options.
 * @param {string} options.componentName - The name of the component.
 * @param {HTMLElement} options.container - The container element to render the component in.
 * @param {string} options.app - The name of the app.
 * @param {Function} options.Component - The component to render.
 * @param {string} options.version - The version of the component.
 * @returns {void}
 *
 * @example
 * const options = {
 *   componentName: 'MyComponent',
 *   container: document.getElementById('appContainer'),
 *   app: createApp(App), // createApp instance defined in the component
 *   Component: App, // previously imported App.vue
 *   version: '1.0.0',
 * };
 *
 * renderComponent(options);
 */
export const renderComponent = ({ componentName, container, app, Component, version, }) => {
    if (container.parentElement?.getAttributeNames().includes('slot') && !isAuthor) {
        setComponent(componentName, Component, version, app);
    }
    else {
        return app.mount(container);
    }
};
/**
 * When using nested components, this method returns all components that are nested.
 * It will return ready to be consumed by the `Vue` `createApp` instance.
 * Components will be injected by {@link https://pages.git.i.mercedes-benz.com/dh-io-seamless/documentation/v6/create/vue/vue3.html#injectslots injectSlots}
 * @param slots
 * @returns
 *
 * @example
 *
 * const slots = getSlots(element);
  const components = getComponents(slots);

  const app = createApp({
    template: `
      ${element.innerHTML}
      <${componentName}>
        ${injectSlots(slots)}
      </${componentName}>
    `,
    components, // It will return an object with component name and reference to `Vue Component`.
  });
 */
export const getComponents = (slots) => {
    return slots.reduce((prev, curr) => {
        const component = getComponent(curr.componentName);
        if (!component) {
            return { ...prev };
        }
        return { ...prev, [component.name]: component.component };
    }, {});
};
/**
 * @experimental - Feature is still in experimental phase.
 * @param environment - defaults to PROD. Can receive `test`, `int` or `preprod` as parameter.
 */
export const aemPluginAlias = (environment) => {
    console.warn(namespace, 'aemPluginAlias method is an experimental feature. It should not be used in production environments.');
    const environmentPrefix = environment ? `${environment}.` : '';
    const baseUrl = 'assets.oneweb.mercedes-benz.com';
    return {
        find: /@seamless\/vue3-aem-plugin\/vue-components/,
        replacement: `https://${environmentPrefix}${baseUrl}/plugin/seamless-vue3-aem-plugin/seamless-vue3-aem-plugin-${version}/vue-components.js`,
    };
};
/**
 * Mounts the nested components in the parent app instance.
 * @param slots - Array of objects with the slot name and the slot content HTML.
 * @param parentAppInstance - The parent app instance.
 */
export const mountNestedComponent = (slots, parentAppInstance) => {
    slots.forEach((slot) => {
        const component = getComponent(slot.componentName);
        if (component && parentAppInstance) {
            const componentNodes = parentAppInstance.$el.querySelectorAll(`div[data-component-name="${slot.componentName}"]`);
            componentNodes.forEach((node) => {
                const app = component.app;
                app.mount(node);
            });
        }
    });
};
